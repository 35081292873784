import React from 'react';
import {
	faFaceFrown,
	faFaceMeh,
	faFaceSmile,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const getGraphData = registrations => {
	registrations = Object.values(registrations);

	let needSupport = [],
		gotSupport = [];

	if (registrations.length) {
		let needsAttention = 0;
		let needsNoAttention = 0;
		let needsSomeAttention = 0;
		let contactedAttention = 0;
		let contactedNoAttention = 0;

		registrations.forEach(registration => {
			const questions = registration.questions;
			const allTrue = questions.every(q => q.needAttention === true);
			const allFalse = questions.every(q => q.needAttention === false);
			const someTrue = questions.some(q => q.needAttention === true);

			if (allTrue) needsAttention++;
			if (allFalse) needsNoAttention++;
			if (someTrue && !allTrue) needsSomeAttention++;

			if (registration.contacted) contactedAttention++;
			if (registration.contacted && allFalse) contactedNoAttention++;
		});

		needSupport = [
			{
				base: registrations.length,
				value: needsAttention,
				className: 'danger',
			},
			{
				base: registrations.length,
				value: needsSomeAttention,
				className: 'warning',
			},
			{
				base: registrations.length,
				value: needsNoAttention,
				className: 'success',
			},
		];

		gotSupport = [
			{
				base:
					needsAttention + needsSomeAttention + contactedNoAttention,
				value: contactedAttention,
			},
		];
	}

	return { needSupport, gotSupport };
};

export const getGraphQuestionData = (question, registrations) => {
	const answers = Object.values(registrations).flatMap(item =>
		item.questions.filter(q => q.id === question.id),
	);

	const base = answers.length;

	if (question.type === 'S') {
		return getSmileyData(answers, base);
	} else if (question.type === 'O') {
		return;
	}

	return getMultipleData(question, answers, base);
};

const getMultipleData = (question, answers, base) => {
	return question.options.map(option => ({
		label: option.option,
		base: base,
		value: answers.filter(x => x.answer === option.option).length,
	}));
};

const getSmileyData = (answers, base) => {
	return [
		{
			label: <FontAwesomeIcon icon={faFaceSmile} fixedWidth />,
			base: base,
			value: answers.filter(x => x.answer === 'green').length,
			className: 'success',
		},
		{
			label: <FontAwesomeIcon icon={faFaceMeh} fixedWidth />,
			base: base,
			value: answers.filter(x => x.answer === 'orange').length,
			className: 'warning',
		},
		{
			label: <FontAwesomeIcon icon={faFaceFrown} fixedWidth />,
			base: base,
			value: answers.filter(x => x.answer === 'red').length,
			className: 'danger',
		},
	];
};
