import { dispatchAction, executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';
import { createFormData } from 'core/services/form';
import { successMessageService } from 'core/services/message';

export const getPlanning = async params => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/kb',
			params,
		});

		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		throw error;
	}
};

export const postTimesheet = (body, kContact, kProject, followup) => () => {
	dispatchAction({
		type: '',
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/kb/${kContact}/${kProject}`,
					method: 'post',
					body: createFormData(body),
				},
				commit: { type: '' },
			},
			toast: {
				type: 'success',
				text: successMessageService('timesheet', 'created'),
			},
			followup: [() => followup(kContact, kProject)],
		},
	});
};
