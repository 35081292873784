import React, { useState } from 'react';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';

import { Detail } from './index';

export const accountTabs = [
	{ label: 'up-freelance', name: 'UP Freelance' },
	{ label: 'kb-consultancy', name: 'KB Consultancy' },
	{ label: 'kb-freelance', name: 'KB Freelance' },
];

const Factorisation = () => {
	const date = new Date();

	const [year, setYear] = useState(date.getFullYear());
	const [month, setMonth] = useState(date.getMonth() + 1);
	const [filter, setFilter] = useState(accountTabs[0]);

	const handleFilter = filter => {
		setFilter(filter);
		/*setFilteredList(
			movingMissions.filter(x =>
				filter.name === 'all' ? !isPast(x.endDate) : isPast(x.endDate),
			),
		);*/
	};

	const prev = () => {
		if (month > 1) {
			setMonth(month - 1);
		} else {
			setMonth(12);
			setYear(year - 1);
		}
	};

	const next = () => {
		if (month < 12) {
			setMonth(month + 1);
		} else {
			setMonth(1);
			setYear(year + 1);
		}
	};

	return (
		<section className='facturation'>
			<AdminHeader
				title='Facturatie'
				filter={
					<>
						{accountTabs.map(({ name, label }, i) => (
							<button
								key={i}
								type='button'
								onClick={() => {
									handleFilter({ name, label });
								}}
								className={`btn btn--small btn--${
									filter.name === name
										? `primary`
										: `secondary`
								}`}>
								{label}
							</button>
						))}
					</>
				}
			/>

			<div className='pagination'>
				<ul className='pagination__pages'>
					<li>
						<a onClick={prev}>
							<span>
								<FontAwesomeIcon icon={faChevronLeft} /> Vorige
							</span>
						</a>
					</li>
					<li>
						{month.toString().padStart(2, '0')}-{year}
					</li>
					<li>
						<a onClick={next}>
							<span>
								Volgende{' '}
								<FontAwesomeIcon icon={faChevronRight} />
							</span>
						</a>
					</li>
				</ul>
			</div>

			<Detail year={year} month={month} type={filter.label} />
		</section>
	);
};

export default Factorisation;
