import React, { useEffect, useState } from 'react';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
	faHashtag,
	faPlus,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	wellBeingOptionFormData,
	wellBeingQuestionFormData,
} from 'core/helpers/well-being/well-being.form-data';
import { useForm } from 'core/hooks/form/useForm';

import DataTable from 'components/DataTable/DataTable';
import { Button, Form, InputField, Select } from 'components/Form';
import Modal from 'components/Modal/Modal';

const AddWellBeingQuestion = props => {
	const [question, setQuestion] = useState(null);
	const [form] = useState(wellBeingQuestionFormData());
	const [showModal, setShowModal] = useState(false);
	const [showOptionModal, setShowOptionModal] = useState(false);

	const { register, getValue, getValues, setValue, isValid, reset } =
		useForm();

	const saveQuestion = () => {
		if (question !== null) {
			props.setValue(
				props.value.map((v, i) => {
					if (i !== question) return v;
					return {
						...v,
						...getValues(),
						sort: i + 1,
					};
				}),
			);
		} else {
			props.setValue([
				...props.value,
				{ ...getValues(), sort: props.value.length + 1 },
			]);
		}

		reset();
		setShowModal(false);
		setQuestion(null);
	};

	const saveOption = option => {
		setValue('options', [...getValue('options'), option]);
	};

	const handleRemove = index => {
		const questions = props.value;
		questions.splice(index, 1);
		props.setValue(questions);
	};

	const handleRemoveAll = () => {
		props.setValue([]);
	};

	const handleRemoveOption = index => {
		const options = getValue('options');
		options.splice(index, 1);
		setValue('options', options);
	};

	const handleRemoveAllOptions = () => {
		setValue('options', []);
	};

	useEffect(() => {
		if (question !== null) {
			const q = props.value[question];

			setValue('question', q?.question ?? '');
			setValue('type', q?.type ?? '');
			if (q.options && q.options.length) {
				setValue('options', q?.options ?? []);
			}
		}
	}, [question]);

	return (
		<>
			<DataTable
				columns={[
					{
						name: 'sort',
						label: '#',
						sort: false,
					},
					{
						name: 'question',
						label: 'Vraag',
						sort: false,
					},
					{
						name: 'type',
						label: 'Type',
						sort: false,
					},
					{
						name: 'questions',
						label: 'Vragen',
						sort: false,
					},
					...(props.hasRegistrations
						? []
						: [
								{
									name: 'actions',
									label: '',
									sort: false,
									actions: {
										update: index => {
											setShowModal(true);
											setQuestion(index);
										},
										remove: index => handleRemove(index),
									},
									className: 'right',
								},
							]),
				]}
				data={props.value.map(x => ({
					...x,
					type:
						x.type === 'M'
							? 'Meerkeuzevraag'
							: x.type === 'S'
								? 'Smilies'
								: 'Open vraag',
					questions: x.options?.map(y => (
						<div key={y.option}>
							{y.option}
							{y.triggerEmail ? (
								<>
									{' '}
									<FontAwesomeIcon
										icon={faEnvelope}
										fixedWidth
									/>
								</>
							) : (
								''
							)}
						</div>
					)),
				}))}
				add={
					props.hasRegistrations
						? null
						: () => setShowModal(!showModal)
				}
				remove={props.hasRegistrations ? null : handleRemoveAll}
				style={{ minWidth: '61rem' }}
			/>

			<Modal
				show={showModal}
				close={() => {
					setShowModal(false);
					setQuestion(null);
					reset();
				}}
				title='Geef de waarden in'
				actions={[
					{
						action: saveQuestion,
						label: 'Bewaren',
						disabled: !(
							isValid() &&
							(getValue('type') !== 'M' ||
								getValue('options')?.length)
						),
					},
				]}>
				<InputField {...register('question', form.question)} />
				<Select {...register('type', form.type)} />
				<InputField {...register('options', form.options)} />

				{getValue('type') === 'M' ? (
					<>
						<Button
							buttonStyle='primary'
							onClick={() => setShowOptionModal(!showOptionModal)}
							buttonSize='small'>
							<FontAwesomeIcon icon={faPlus} fixedWidth /> Optie
							toevoegen
						</Button>

						{getValue('options')?.length !== 0 ? (
							<div className='form-field__upload-file-section'>
								{getValue('options')?.map((option, index) => (
									<div
										className='form-field__upload-file'
										key={index}>
										<div className='form-field__upload-file-label'>
											<FontAwesomeIcon
												icon={faHashtag}
												fixedWidth
											/>
											<span>
												{option.option}
												{option.triggerEmail ? (
													<>
														{' '}
														<FontAwesomeIcon
															icon={faEnvelope}
															fixedWidth
														/>
													</>
												) : null}
											</span>
										</div>
										<div className='form-field__upload-file__buttons'>
											<button
												className='form-field__upload-file-remove-button'
												onClick={() =>
													handleRemoveOption(index)
												}>
												<FontAwesomeIcon
													icon={faTimes}
													fixedWidth
												/>
											</button>
										</div>
									</div>
								))}
								<div className='form-field__upload-buttons'>
									<button
										className='form-field__upload-remove-all-button'
										onClick={handleRemoveAllOptions}>
										<FontAwesomeIcon
											icon={faTrash}
											fixedWidth
										/>
										<span>Verwijder alles</span>
									</button>
								</div>
							</div>
						) : null}
					</>
				) : null}
			</Modal>

			<Form
				fields={wellBeingOptionFormData()}
				submitOnClick={value => saveOption(value)}
				showModal={showOptionModal}
				setShowModal={setShowOptionModal}
				modalSize='small'
				addCancel
				isModal
			/>
		</>
	);
};

export default AddWellBeingQuestion;
