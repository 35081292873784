import { useEffect, useState } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSubmit } from 'core/hooks/submit/useSubmit';

import { Button, Form } from 'components/Form';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';

import { factForm } from './kb.helper';
import { getPlanning, postTimesheet } from './kb-service';

import './kb.scss';

const Detail = ({ month, year, type }) => {
	const { handleSubmit } = useSubmit();

	const [loading, setLoading] = useState(false);
	const [planning, setPlanning] = useState([]);
	const [contact, setContact] = useState();

	const form = factForm(null, type !== 'kb-consultancy');

	useEffect(() => {
		getTimesheetData(year, month, type);
	}, [year, month, type]);

	const getTimesheetData = async (year, month, type) => {
		setLoading(true);
		try {
			const planning = await getPlanning({ year, month, type });
			setPlanning(planning);
		} finally {
			setLoading(false);
		}
	};

	const submitForm = async values => {
		await handleSubmit(
			postTimesheet(
				{ ...values, year, month },
				contact.contact.key,
				contact.projects.key,
				followup,
			),
		);
	};

	const followup = (kContact, kProject) => {
		setPlanning(prevState =>
			prevState.map(x => {
				if (x.projects.key === kProject && x.contact.key === kContact) {
					return {
						...x,
						status: 1,
					};
				}

				return x;
			}),
		);
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<table className='table planning'>
					<thead>
						<tr>
							<th>Naam</th>
							<th className='border-left center'>Status</th>
							<th className='border-left'>Bedrijf</th>
							<th>Project</th>
							<th className='center'>TS</th>
						</tr>
					</thead>
					<tbody>
						{planning.length ? (
							planning.map(({ contact, projects, status }) => (
								<tr
									key={`${contact.name}-${projects.company.name}-${projects.name}`}>
									<td>
										<Button
											buttonStyle='link'
											label={`${contact.name} ${contact.firstname}`}
											onClick={() =>
												setContact({
													contact,
													projects,
													status,
												})
											}
										/>
									</td>
									<td
										className={`border-left center ${status ? 'DONE' : 'NOK'}`}>
										{status ? 'DONE' : 'NOK'}
									</td>

									<td className='border-left'>
										{projects.company.name}
									</td>
									<td>{projects.name}</td>
									<td className='center'>
										{projects.noTs ? (
											<FontAwesomeIcon
												icon={faTimes}
												fixedWidth
											/>
										) : (
											<FontAwesomeIcon
												icon={faCheck}
												fixedWidth
											/>
										)}
									</td>
								</tr>
							))
						) : (
							<tr>
								<td
									colSpan={5}
									className='no-data-message'
									style={{
										textAlign: 'center',
										padding: 10,
									}}>
									Geen projecten
								</td>
							</tr>
						)}
					</tbody>
				</table>
			)}

			{contact ? (
				!contact.status ? (
					<Form
						showModal={!!contact}
						layout={form.layout}
						fields={form.fields}
						modalSize='large'
						modalTitle='Urenregistratie'
						modalSubtitle={`${contact.contact.name} ${contact.contact.firstname} - ${contact.projects.name}`}
						submitOnClick={submitForm}
						setShowModal={() => setContact(null)}
						addCancel
						isModal
					/>
				) : (
					<Modal
						show={!!contact}
						close={() => setContact(null)}
						title='Urenregistratie'
						subTitle={`${contact.contact.name} ${contact.contact.firstname} - ${contact.projects.name}`}
						small>
						Voor deze persoon is data ingegeven in Efficy.
						<br />
						Je kan de data niet ingeven via KetchUP.
					</Modal>
				)
			) : null}
		</>
	);
};

export default Detail;
