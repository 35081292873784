import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	faChalkboardTeacher,
	faChampagneGlasses,
	faChevronRight,
	faFileInvoice,
	faGraduationCap,
	faHandHoldingHeart,
	faHouse,
	faNewspaper,
	faRankingStar,
	faTasks,
	faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changeSidebar } from 'core/store/authentication/auth.actions';

import './Navigation.scss';

const Navigation = ({ isOpen, toggleSidebar }) => {
	const { permissions } = useSelector(state => state.authReducer);
	const [width, setWidth] = useState(0);

	const sidebarRef = useRef();

	const handleClickOutsideDropdown = e => {
		if (
			(sidebarRef.current && sidebarRef.current.contains(e.target)) ||
			width >= 991
		)
			return true;
		toggleSidebar(false);
	};

	const handleScrollOutsideDropdown = e => {
		if (
			(sidebarRef.current && sidebarRef.current.contains(e.target)) ||
			width >= 991
		)
			return true;
		toggleSidebar(false);
	};

	const updateWidth = () => {
		setWidth(window.innerWidth);
		toggleSidebar();
	};

	useEffect(() => {
		window.addEventListener('resize', updateWidth);
		return () => window.removeEventListener('resize', updateWidth);
	});

	useEffect(() => {
		document.addEventListener(
			'mousedown',
			handleClickOutsideDropdown,
			false,
		);
		document.addEventListener('wheel', handleScrollOutsideDropdown, false);

		return function cleanup() {
			document.removeEventListener(
				'mousedown',
				handleClickOutsideDropdown,
				false,
			);
			document.removeEventListener(
				'wheel',
				handleScrollOutsideDropdown,
				false,
			);
		};
	});

	return (
		<nav
			className={`navigation${isOpen ? ' is_open' : ''}`}
			ref={sidebarRef}>
			<ul className='navigation__nav'>
				<li className='navigation__nav-item'>
					<NavLink to='/' className='navigation__nav-link'>
						<span>Dashboard</span>
						<FontAwesomeIcon
							icon={faHouse}
							className='icon'
							fixedWidth
						/>
					</NavLink>
				</li>

				{permissions.includes('news_admin') ? (
					<li className='navigation__nav-item'>
						<NavLink to='/nieuws' className='navigation__nav-link'>
							<span>Nieuws</span>
							<FontAwesomeIcon
								icon={faNewspaper}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}

				{permissions.includes('trainings_admin') ? (
					<li className='navigation__nav-item'>
						<NavLink
							to='/opleidingen'
							className='navigation__nav-link'>
							<span>Opleidingen</span>
							<FontAwesomeIcon
								icon={faGraduationCap}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}

				{permissions.includes('events_admin') ? (
					<li className='navigation__nav-item'>
						<NavLink
							to='/evenementen'
							className='navigation__nav-link'>
							<span>Evenementen</span>
							<FontAwesomeIcon
								icon={faChampagneGlasses}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}

				{permissions.includes('moving_mission_admin') ? (
					<li className='navigation__nav-item'>
						<NavLink
							to='/moving-mission'
							className='navigation__nav-link'>
							<span>Moving Mission</span>
							<FontAwesomeIcon
								icon={faRankingStar}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}

				{permissions.includes('well_being_admin') ? (
					<li className='navigation__nav-item'>
						<NavLink to='/welzijn' className='navigation__nav-link'>
							<span>Welzijn</span>
							<FontAwesomeIcon
								icon={faHandHoldingHeart}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}

				{permissions.includes('instructors_admin') ? (
					<li className='navigation__nav-item sidebar__nav-dropdown'>
						<NavLink
							to='/lesgevers'
							className='navigation__nav-link'>
							<span>Lesgevers</span>
							<FontAwesomeIcon
								icon={faChalkboardTeacher}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}

				{permissions.includes('permissions_admin') ? (
					<li className='navigation__nav-item sidebar__nav-dropdown'>
						<NavLink
							to='/permissies'
							className='navigation__nav-link'>
							<span>Permissies</span>
							<FontAwesomeIcon
								icon={faTasks}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}

				{permissions.includes('roles_admin') ? (
					<li className='navigation__nav-item sidebar__nav-dropdown'>
						<NavLink
							to='/gebruikersrollen'
							className='navigation__nav-link'>
							<span>Gebruikersrollen</span>
							<FontAwesomeIcon
								icon={faUserGear}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}

				{permissions.includes('user_support') ? (
					<li className='navigation__nav-item sidebar__nav-dropdown'>
						<NavLink
							to='/facturatie'
							className='navigation__nav-link'>
							<span>Facturatie</span>
							<FontAwesomeIcon
								icon={faFileInvoice}
								className='icon'
								fixedWidth
							/>
						</NavLink>
					</li>
				) : null}
			</ul>

			<button
				className='navigation__handle'
				onClick={() => {
					if (width >= 991) {
						changeSidebar();
					}
					toggleSidebar(!isOpen);
				}}
				aria-label='Toggle menu'>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>
		</nav>
	);
};

export default Navigation;
