import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	authLoading: false,
	//loggedIn: false,
	//token: null,
	authId: null,
	//authUser: null,
	//thumbnail: null,
	role: null,
	permissions: [],
	settings: null,
	//contacts: [],
	meInfoFetched: false,
	loginTries: 0,
};

const loadUserProfile = (state, { authLoading }) => ({
	...state,
	authLoading,
	meInfoFetched: true,
});

const loadUserProfileCommit = (state, { permissions, settings, role, id }) => ({
	...state,
	authLoading: false,
	authId: id,
	permissions,
	settings,
	role,
});

const updateSettings = (state, payload) => {
	console.log(payload);
	return {
		...state,
		settings: payload,
	};
};

export const authReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.AUTH_LOAD_PROFILE:
			return loadUserProfile(state, payload);
		case ACTION_TYPES.AUTH_LOAD_PROFILE_COMMIT:
			return loadUserProfileCommit(state, payload.data);
		case ACTION_TYPES.AUTH_UPDATE_SETTINGS_COMMIT:
			return updateSettings(state, payload.data);
		default:
			return state;
	}
};
