import { INPUT_TYPES } from 'core/constants';
import { isRequired } from 'core/services/form';

export const factForm = (object, isFreelance) => {
	return {
		layout: {
			type: 'row',
			name: 'row',
			fields: [
				'col1',
				'col2',
				'col3',
				'col4',
				'col10',
				...(isFreelance ? ['col11'] : []),
				'col5',
				'col6',
				'col7',
				'col8',
				'col9',
			],
		},
		fields: [
			{
				type: 'col',
				name: 'col1',
				breakpoint: { sm: 12, md: 6 },
				fields: ['duration'],
			},
			{
				type: 'col',
				name: 'col2',
				breakpoint: { sm: 12, md: 2 },
				fields: ['h100'],
			},
			{
				type: 'col',
				name: 'col3',
				breakpoint: { sm: 12, md: 2 },
				fields: ['h150'],
			},
			{
				type: 'col',
				name: 'col4',
				breakpoint: { sm: 12, md: 2 },
				fields: ['h200'],
			},
			{
				type: 'col',
				name: 'col5',
				breakpoint: { sm: 12, md: 6 },
				fields: ['doc'],
			},
			{
				type: 'col',
				name: 'col6',
				breakpoint: { sm: 12, md: 6 },
				fields: ['woc'],
			},
			{
				type: 'col',
				name: 'col7',
				breakpoint: { sm: 12, md: 4 },
				fields: ['woc100'],
			},
			{
				type: 'col',
				name: 'col8',
				breakpoint: { sm: 12, md: 4 },
				fields: ['woc150'],
			},
			{
				type: 'col',
				name: 'col9',
				breakpoint: { sm: 12, md: 4 },
				fields: ['woc200'],
			},
			{
				type: 'col',
				name: 'col10',
				breakpoint: { sm: 12, md: isFreelance ? 6 : 12 },
				fields: ['timesheet'],
			},
			{
				type: 'col',
				name: 'col11',
				breakpoint: { sm: 12, md: isFreelance ? 6 : 12 },
				fields: ['invoice'],
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'duration',
				label: 'Duur',
				required: true,
				errors: [isRequired],
				value: '',
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'h100',
				label: '100%',
				errors: [],
				value: '',
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'h150',
				label: '150%',
				errors: [],
				value: '',
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'h200',
				label: '200%',
				errors: [],
				value: '',
			},

			{
				type: INPUT_TYPES.NUMBER,
				name: 'doc',
				label: 'Duur On Call',
				errors: [],
				value: '',
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'woc',
				label: 'Gewerkt On Call',
				errors: [],
				value: '',
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'woc100',
				label: 'On Call 100%',
				errors: [],
				value: '',
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'woc150',
				label: 'On Call 150%',
				errors: [],
				value: '',
			},
			{
				type: INPUT_TYPES.NUMBER,
				name: 'woc200',
				label: 'On Call 200%',
				errors: [],
				value: '',
			},

			{
				type: INPUT_TYPES.FILE,
				label: 'Getekende timesheet',
				name: 'timesheet',
				accept: 'application/pdf',
				isMulti: false,
				showOptions: true,
				value: [
					...(object?.attachments?.map(x => ({
						id: x.id,
						filename: x.filename,
						name: x.description,
						attachment: x.type === 'attachment',
						primary: x.primary,
						type: x.mime,
					})) ?? []),
				],
			},
			{
				type: INPUT_TYPES.FILE,
				label: 'Getekende timesheet',
				name: 'invoice',
				accept: 'application/pdf',
				isMulti: false,
				showOptions: true,
				value: [
					...(object?.attachments?.map(x => ({
						id: x.id,
						filename: x.filename,
						name: x.description,
						attachment: x.type === 'attachment',
						primary: x.primary,
						type: x.mime,
					})) ?? []),
				],
			},
		],
	};
};
