import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { routesConfig } from 'core/routers/routesConfig';
import { bool } from 'prop-types';
import { Factorisation } from 'views';

import Navigation from 'components/Layouts/Navigation/Navigation';
import TopBar from 'components/Layouts/TopBar/TopBar';
import Toasts from 'components/Snackbar/Snackbar';

import './AdminLayout.scss';

const AdminLayout = () => {
	const { submitting } = useSubmit();
	const { state } = useLocation();

	const {
		permissions,
		settings: { adminSidebarOpen },
	} = useSelector(state => state.authReducer);

	const [isSidebarOpen, setIsSidebarOpen] = useState(
		adminSidebarOpen ?? true,
	);

	const toggleSidebar = status => {
		setIsSidebarOpen(status ?? adminSidebarOpen ?? true);
	};

	const buildRoute = (route, index) => {
		return (route.replace ?? false) ? (
			<Route
				key={index}
				index={route.index}
				element={<Navigate to={route.path} state={state} replace />}
			/>
		) : (
			<Route
				key={index}
				path={route.path}
				element={route.element}
				exact={route.exact}
				index={route.index ?? false}>
				{route.children?.length
					? route.children.map((child, i) =>
							buildRoute(child, `${index}-${i}`),
						)
					: null}
			</Route>
		);
	};

	return (
		<div
			className={`admin ${isSidebarOpen ? 'sidebar-open' : ''}${submitting ? ' busy' : ''}`}>
			<TopBar toggleSidebar={toggleSidebar} permissions={permissions} />
			<Navigation isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

			<main className='admin-content'>
				<Routes>
					{routesConfig.map((route, index) => {
						const hasPermission = route.permissions.every(x =>
							permissions.includes(x),
						);

						if (hasPermission) {
							return buildRoute(route, index);
						}
					})}

					{permissions.includes('user_support') ? (
						<Route
							exact
							path='/facturatie'
							element={<Factorisation />}
						/>
					) : null}
				</Routes>
			</main>
			<Toasts />
		</div>
	);
};

AdminLayout.propTypes = {
	online: bool,
};

export default AdminLayout;
