export const getEntityTypeDetails = type => {
	switch (type) {
		case 'users':
			return 'medewerkers';
		case 'evenementen':
		case 'events':
			return {
				name: 'Evenementen',
				title: 'Evenementen',
				single: 'Evenement',
				type: 'events',
			};
		case 'opleidingen':
		case 'trainings':
			return {
				name: 'Opleidingen',
				title: 'Opleidingen',
				single: 'Opleiding',
				type: 'trainings',
			};
		case 'nieuws':
		case 'news':
		default:
			return {
				name: 'Nieuws',
				title: 'Nieuws artikelen',
				single: 'Artikel',
				type: 'news',
			};
	}
};

export const getEntityFilter = (type, permissions) => {
	const filter = [{ name: 'all' }];

	if (type === 'news' && permissions.includes(`news_update`)) {
		filter.push({ name: 'important', label: 'Belangrijk' });
		filter.push({ name: 'pinned', label: 'Pinned' });
	}

	if (permissions.includes(`${type}_see_unpublished`)) {
		filter.push({ name: 'unpublished', label: 'Niet gepubliceerd' });
	}

	return filter;
};

export const getEntityRegistrationFilter = () => {
	return [
		{ name: 'all' },
		{
			name: 'present',
			label: (
				<>
					<span className='success' />
					Aanwezig
				</>
			),
		},
		{
			name: 'notPresent',
			label: (
				<>
					<span className='danger' />
					Niet aanwezig
				</>
			),
		},
		{
			name: 'unconfirmed',
			label: (
				<>
					<span className='warning' />
					Onbevestigd
				</>
			),
		},
		{
			name: 'followup',
			label: (
				<>
					<span className='info' />
					Opvolging
				</>
			),
		},
	];
};

export const seniority = [
	{
		id: 1,
		name: '0-5',
		min: 0,
		max: 4,
	},
	{
		id: 2,
		name: '5-10',
		min: 5,
		max: 9,
	},
	{
		id: 3,
		name: '10-15',
		min: 10,
		max: 14,
	},
	{
		id: 4,
		name: '15+',
		min: 15,
		max: 100,
	},
];
